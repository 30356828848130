<template>
  <div>
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p class="is-size-5">コンテンツの公開期間が終了しました。</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ['volume']
}
</script>

<template>
  <div v-if="volume && content">
    <template v-if="volume.status !== 'published'">
      <closed-message :volume="volume" />
    </template>

    <template v-else>
      <div style="padding-bottom: 6rem;">
        <hero :volume="volume" :title="content.title" />

        <div class="notification is-info is-light p-5 mb-5 category" style="max-width: 800px;" v-if="content.categoryRef.title && content.categoryRef.description">
          <p class="title is-6">{{ content.categoryRef.title }}</p>
          <div class="subtitle is-6">{{ content.categoryRef.description }}</div>
        </div>

        <div class="video-area" style="max-width: 800px;">
          <a class="button is-medium is-white like-button" :class="{ 'is-liked': isLiked }" @click="toggleLike">
            <span class="icon is-small">
              <fa-icon :icon="[likeIconPack, 'heart']" />
            </span>
            <span>{{ content.likeCount || 0 }}</span>
          </a>

          <div class="video-wrapper">
            <iframe :src="videoUrl" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>

          <div class="content-meta">
            <p class="has-text-weight-bold">{{ content.title }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import { firebase, db } from '@/main'
import Hero from '@/components/Hero'
import ClosedMessage from '@/components/ClosedMessage'

export default {
  title: 'コンテンツ',
  components: { Hero, ClosedMessage },
  data() {
    return {
      volumeId: this.$route.params.volumeId,
      contentId: this.$route.params.contentId,
      userId: null,
      volume: null,
      content: null,
      isLiked: false,
      isAccessed: false,
    }
  },
  firestore() {
    const volumeRef = db.collection('volumes').doc(this.volumeId)

    return {
      volume: volumeRef,
      content: volumeRef.collection('contents').doc(this.contentId)
    }
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.userId = user.uid

        this.accessUserRef.get().then((doc) => {
          if (doc.exists) return

          this.accessUserRef.set({
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            this.$firestoreRefs.content.update({
              accessCount: firebase.firestore.FieldValue.increment(1)
            })
          })
        })

        this.likeUserRef.get().then((doc) => {
          if (doc.exists) this.isLiked = true
        })
      }
    })
  },
  methods: {
    toggleLike: function() {
      if (this.isLiked) {
        this.likeUserRef.delete().then(() => {
          this.$firestoreRefs.content.update({
            likeCount: firebase.firestore.FieldValue.increment(-1)
          }).then(() => {
            this.isLiked = false
          })
        })
      } else {
        this.likeUserRef.set({
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
          this.$firestoreRefs.content.update({
            likeCount: firebase.firestore.FieldValue.increment(1)
          }).then(() => {
            this.isLiked = true
          })
        })
      }
    }
  },
  computed: {
    likeUserRef: function() {
      return this.userId ? this.$firestoreRefs.content.collection('likeUsers').doc(this.userId) : null
    },
    accessUserRef: function() {
      return this.userId ? this.$firestoreRefs.content.collection('accessUsers').doc(this.userId) : null
    },
    videoUrl: function() {
      return `https://player.vimeo.com/video/${this.content.videoId}`
    },
    likeIconPack: function() {
      return this.isLiked ? 'fas' : 'far'
    }
  }
}
</script>


<style lang="scss" scoped>
  .category {
    margin: 0 auto;

    .subtitle {
      white-space: pre-wrap;
    }
  }

  .video-area {
    margin: 0 auto;
    position: relative;

    .like-button {
      background-color: rgba(255, 255, 255, 1);
      color: rgb(91, 112, 131);
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 2em;
      z-index: 1;

      &:hover, &.is-liked {
        color: rgb(224, 36, 94);
      }
    }

    .video-wrapper {
      background-color: #000;
      width: 100%;
      height: 0;
      position: relative;
      padding: 0 0 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .content-meta {
    background-color: whitesmoke;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0.75rem 1rem;
  }
</style>
